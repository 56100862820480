import { useMeasure } from "@archery-inc/design-system";
import {
  AiRevealAspectRatio,
  AiRevealImagePosition,
} from "../../__generated__/graphql";
import { amplitude } from "../../api/third-party";
import { useMemo, useState } from "react";

export interface RemovedBackgroundFileInfo {
  url: string;
  bucketName: string;
  filePath: string;
}

export type Step =
  | "photo-upload"
  | "prepare-photo"
  | "preset-selection"
  | "generation";

export interface AiState {
  step: Step;
  aspectRatio: AiRevealAspectRatio;
  imagePosition: AiRevealImagePosition;
  removedBackgroundFileInfo?: RemovedBackgroundFileInfo;
}

export type Action =
  | {
      type: "remove-background";
      imgData: RemovedBackgroundFileInfo;
    }
  | {
      type: "set-aspect-ratio";
      aspectRatio: AiRevealAspectRatio;
    }
  | {
      type: "set-image-position";
      imagePosition: AiRevealImagePosition;
    }
  | {
      type: "validate-image";
    }
  | {
      type: "cancel-image";
    };

export function reducer(state: AiState, action: Action): AiState {
  switch (action.type) {
    case "remove-background":
      return {
        ...state,
        step: "prepare-photo",
        removedBackgroundFileInfo: action.imgData,
      };
    case "set-aspect-ratio":
      return {
        ...state,
        aspectRatio: action.aspectRatio,
      };
    case "set-image-position":
      return {
        ...state,
        imagePosition: action.imagePosition,
      };
    case "validate-image":
      return {
        ...state,
        step: "preset-selection",
      };
    case "cancel-image":
      amplitude.logEvent("Web:Client:AiReveals:PreparePhoto:Close");
      return {
        ...state,
        step: "photo-upload",
      };
  }
}

export function useImagePosition(imgPosition: AiRevealImagePosition) {
  const [imgAspectRatio, setImgAspectRatio] = useState(0);
  const [ref, { width, height }] = useMeasure();
  const [imgW, imgH] = useMemo(() => {
    if (!width || !height || !imgAspectRatio) return [undefined, undefined];
    const margin_percent = 0.1;
    let w = Math.floor((1 - 2 * margin_percent) * width);
    let h = Math.ceil(w / imgAspectRatio);
    if (h > height) {
      h = Math.floor((1 - 2 * margin_percent) * height);
      w = Math.ceil(h * imgAspectRatio);
    }
    return [w, h];
  }, [imgAspectRatio, width, height]);

  const [x, y] = useMemo(() => {
    if (!imgW || !imgH || !width || !height) return [undefined, undefined];
    const margin = 0.05 * width;
    switch (imgPosition) {
      case AiRevealImagePosition.Center:
        return [(width - imgW) / 2, (height - imgH) / 2];
      case AiRevealImagePosition.Left:
        return [margin, (height - imgH) / 2];
      case AiRevealImagePosition.Right:
        return [width - imgW - margin, (height - imgH) / 2];
      case AiRevealImagePosition.Top:
        return [(width - imgW) / 2, margin];
      case AiRevealImagePosition.Bottom:
        return [(width - imgW) / 2, height - imgH - margin];
      case AiRevealImagePosition.TopLeft:
        return [margin, margin];
      case AiRevealImagePosition.TopRight:
        return [width - imgW - margin, margin];
      case AiRevealImagePosition.BottomLeft:
        return [margin, height - imgH - margin];
      case AiRevealImagePosition.BottomRight:
        return [width - imgW - margin, height - imgH - margin];
      default:
        return [(width - imgW) / 2, (height - imgH) / 2];
    }
  }, [imgW, imgH, width, height, imgPosition]);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImgAspectRatio(
      e.currentTarget.offsetWidth / e.currentTarget.offsetHeight,
    );
  };

  return { ref, imgW, imgH, x, y, onImageLoad };
}
