import { BrowserRouter } from "react-router-dom";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { IntercomProvider } from "react-use-intercom";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { initThirdPartyApis } from "./api/third-party.ts";
import "@archery-inc/design-system/dist/index.css";
import "./index.css";

initThirdPartyApis();

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <IntercomProvider autoBoot appId={INTERCOM_APP_ID}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IntercomProvider>,
);

if (import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}
