import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import useInfiniteScroll from "./useInfiniteScroll";
import { loadStripe } from "@stripe/stripe-js";
import { AiRevealAspectRatio } from "../../__generated__/graphql";
import { GET_PROFILE } from "./profile";

const GET_PRESETS = gql(`
  query Presets($filters: AiAnimatedElementPresetsFilters) {
    getAiAnimatedElementPresets(filters: $filters) {
      presets {
        presetId
        isPro
        isPresetNew
        previewVideoUrl
      }
      nextCursor
    }
  }
`);

export function usePresetsQuery({ showHidden = false } = {}) {
  return useQuery(GET_PRESETS, {
    variables: { filters: { showHidden } },
    context: {
      fetchOptions: {
        next: { revalidate: 0 },
      },
    },
  });
}

const GET_GENERATION = gql(`
  query GetAiAnimatedElementsGeneration($generationId: ID!) {
    getAiAnimatedElementsGeneration(generationId: $generationId) {
      generationId
      progress
      notifyEmail
      status
      errorMessages
      inputFileUrl
      feedback {
        rating
        message
      }
      inputBucketInfo {
        filePath
        bucketName
      }
      presets {
        aspectRatio
        imagePosition
        presetId
        seed
        task {
          _id
          type
          status
          jsonResult
          parsedResult
          createdAt
          updatedAt
        }
      }
    }
  }
`);

export function useGenerationQuery({ generationId }: { generationId: string }) {
  return useQuery(GET_GENERATION, { variables: { generationId } });
}

const MY_GENERATIONS = gql(`
  query MyGenerations($limit: Int, $cursor: String) {
    myAiGenerations(limit: $limit, cursor: $cursor) {
      data {
        generationId
        inputBucketInfo {
          bucketName
          filePath
        }
        status
        presets {
          aspectRatio
          imagePosition
          presetId
          task {
            status
            parsedResult
          }
        }
      }
      nextCursor
    }
  }
`);

export function useMyGenerationsQuery(variables?: {
  limit?: number;
  cursor?: string;
}) {
  const query = useQuery(MY_GENERATIONS, {
    variables,
    fetchPolicy: "cache-and-network",
  });
  const infiniteQuery = useInfiniteScroll({
    data: query.data?.myAiGenerations,
    limit: variables?.limit,
    fetchMore(cursor, limit) {
      return query.fetchMore({
        variables: { filters: { cursor, limit } },
        updateQuery(previous, { fetchMoreResult }) {
          return {
            ...previous,
            myAiGenerations: {
              data: [
                ...previous.myAiGenerations.data,
                ...fetchMoreResult.myAiGenerations.data,
              ],
              nextCursor: fetchMoreResult.myAiGenerations.nextCursor,
            },
          };
        },
      });
    },
  });

  return { ...infiniteQuery, ...query };
}

const CREATE_AI_FROM_PRESET = gql(`
  mutation CreateAiAnimatedElementsWithPresets(
    $inputFileUrl: String!
    $presets: [PresetConfig!]
    $inputBucketInfo: BucketInfoInput
  ) {
    createAiAnimatedElementsWithPresets(
      inputFileUrl: $inputFileUrl
      presets: $presets
      inputBucketInfo: $inputBucketInfo
    ) {
      generationId
      notifyEmail
      inputFileUrl
      inputBucketInfo {
        filePath
        bucketName
      }
      presets {
        presetId
        seed
        task {
          _id
          status
          jsonResult
          type
          updatedAt
          parsedResult
          jsonPayload
          createdAt
        }
      }
    }
  }
`);

export function useCreateAi() {
  return useMutation(CREATE_AI_FROM_PRESET, {
    refetchQueries: [GET_PROFILE],
  });
}

const NOTIFY_EMAIL_ON_GENERATION_COMPLETE = gql(`
  mutation NotifyEmailOnGenerationComplete(
    $generationId: ID!
    $notifyEmail: String!
  ) {
    notifyEmailOnGenerationComplete(
      generationId: $generationId
      notifyEmail: $notifyEmail
    ) {
      generationId
      notifyEmail
      userId
      inputFileUrl
      status
      errorMessages
    }
  }
`);

const SEND_FEEDBACK_FOR_GENERATION = gql(`
  mutation SendFeedbackForGeneration(
    $generationId: ID!
    $rating: Int!
    $message: String
  ) {
    sendFeedbackForGeneration(
      generationId: $generationId
      rating: $rating
      message: $message
    ) {
      generationId
    }
  }
`);

export function useNotifyEmailOnGenerationComplete(
  generationId: string,
  notifyEmail: string,
) {
  return useMutation(NOTIFY_EMAIL_ON_GENERATION_COMPLETE, {
    variables: { generationId, notifyEmail },
  });
}

export function useSendFeedbackForGeneration() {
  return useMutation(SEND_FEEDBACK_FOR_GENERATION);
}

const GENERATE_ANIMATED_PICTURE_INPUT = gql(`
  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!) {
    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl) {
      removedBackgroundPictureUrl
      bucketName
      filePath
    }
  }
`);

export function useGenerateWorkflowInput() {
  const generateWorflow = useLazyQuery(GENERATE_ANIMATED_PICTURE_INPUT)[0];
  return (pictureUrl: string) => generateWorflow({ variables: { pictureUrl } });
}

const CANCEL_GENERATION = gql(`
  mutation CancelGeneration($generationId: ID!) {
    cancelGeneration(generationId: $generationId) {
      generationId
      status
    }
  }
`);

export function useCancelGeneration() {
  return useMutation(CANCEL_GENERATION);
}

const SEND_EMAIL = gql(`
  mutation SubscribeToWaitingList($email: String!) {
    subscribeToWaitingList(email: $email)
  }
`);

export function useSubscribeToWaitingList() {
  return useMutation(SEND_EMAIL);
}

const CREATE_FROM_PROJECT_FROM_AI = gql(`
  mutation CreateProjectFromGeneration($generationId: ID!) {
    createProjectFromGeneration(generationId: $generationId) {
      _id
    }
  }
`);

export function useCreateProjectFromGeneration() {
  return useMutation(CREATE_FROM_PROJECT_FROM_AI);
}

const GET_AI_TOKENS = gql(`
  query GetAiTokensPlans {
    getAiTokensPlans {
      productId
      name
      currency {
        isoCode
        symbol
        showBefore
      }
      tokensGiven
      price
    }
  }
`);

export function useGetAiTokens() {
  return useQuery(GET_AI_TOKENS);
}

const STRIPE_SESSION = gql(`
  query GetOneOffCheckoutSession($productId: String!) {
    getOneOffCheckoutSession(productId: $productId) {
      sessionId
    }
  }
`);

export function useStripeSession() {
  const [getSession] = useLazyQuery(STRIPE_SESSION);
  return async (productId: string) => {
    const stripe = await loadStripe(
      import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    );
    const { data } = await getSession({ variables: { productId } });
    const sessionId = data?.getOneOffCheckoutSession?.sessionId;
    if (sessionId) await stripe?.redirectToCheckout({ sessionId });
  };
}

export function getFormatFromAspectRatio(ratio: AiRevealAspectRatio): string {
  switch (ratio) {
    case AiRevealAspectRatio.Landscape:
      return "16 / 9";
    case AiRevealAspectRatio.Portrait:
      return "9 / 16";
    case AiRevealAspectRatio.Vertical:
      return "4 / 5";
    case AiRevealAspectRatio.Square:
      return "1 / 1";
    default:
      return "9 / 16";
  }
}
