import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { app } from "./firebaseApp";
import { useEffect } from "react";
import { useMainStore } from "../common/store";

export function uploadFile(
  file: File,
  bucketName: string,
  uploadPath: string,
  { appendFileName = true } = {},
) {
  const storage = getStorage(app, "gs://" + bucketName);
  const storageRef = ref(
    storage,
    uploadPath + (appendFileName ? `/${file.name}` : ""),
  );
  return uploadBytes(storageRef, file);
}

export function deleteFile(bucketName: string, fileName: string) {
  const storage = getStorage(app, "gs://" + bucketName);
  const gcsRef = ref(storage, fileName);
  return deleteObject(gcsRef);
}

export function getFileUrl(bucketName: string, fileName: string) {
  const storage = getStorage(app, "gs://" + bucketName);
  const gcsRef = ref(storage, fileName);
  return getDownloadURL(gcsRef).catch(() => null);
}

export function getFileRef(bucketName: string, filePath: string) {
  return ref(getStorage(app, "gs://" + bucketName), filePath);
}

export function useFileUrl(bucket?: string, path?: string) {
  const setCache = useMainStore((state) => state.setCacheUrl);
  const url = useMainStore((state) => state.urlCache[`${bucket}.${path}`]);

  useEffect(() => {
    if (bucket && path && !url) {
      void setCache(bucket, path);
    }
  }, [bucket, path, url, setCache]);

  return url;
}

export async function downloadFromUrl(videoUrl: string, name: string) {
  const r = await fetch(videoUrl);
  const res = await r.blob();

  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(res);
  a.download = `${name}.mp4`;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
