export default function ProgressBar({ progress }: { progress: number }) {
  return (
    <div className="w-full h-full bg-grey-3 rounded">
      <div
        className="h-full bg-tangerine rounded transition-all duration-200"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}
