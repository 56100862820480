import { useState } from "react";

interface StarRatingProps {
  rating?: number | null;
  frozen?: boolean;
  onRatingChange?: (newRating: number) => void;
}

export default function StarRating({
  rating: initialRating = null,
  frozen = false,
  onRatingChange,
}: StarRatingProps) {
  const [rating, setRating] = useState(initialRating);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const totalStars = 5;

  const handleStarClick = (index: number) => {
    if (!frozen) {
      const newRating = index + 1;
      setRating(newRating);
      if (onRatingChange) {
        onRatingChange(newRating);
      }
    }
  };

  const handleMouseEnter = (index: number) => {
    if (!frozen) {
      setHoveredStar(index);
    }
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  return (
    <div className="flex items-center">
      {[...(Array(totalStars) as number[])].map((_, index) => (
        <svg
          key={index}
          onClick={() => handleStarClick(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          className={`w-5 h-5 ms-1.5 ${!frozen ? "cursor-pointer" : ""} ${
            rating !== null && index < rating ? "text-yellow" : "text-black"
          } ${!frozen && hoveredStar !== null && index <= hoveredStar ? "text-yellow" : ""}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      ))}
    </div>
  );
}
