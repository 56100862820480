import { useCallback, useState } from "react";
import { InputMaybe } from "../../__generated__/graphql";

export default function useInfiniteScroll({
  data,
  fetchMore,
  limit,
}: {
  data?: { data?: unknown[]; nextCursor?: string | null };
  fetchMore: (cursor: string, limit?: InputMaybe<number>) => Promise<unknown>;
  limit?: InputMaybe<number>;
}) {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  const loadMore = useCallback(async () => {
    if (!data) {
      return;
    }
    setIsFetchingMore(true);

    const lastProject = data.data!.slice(-1)[0];
    if (!lastProject || (data?.data?.length ?? 0) % limit! !== 0) {
      setIsFetchingMore(false);
      setHasNextPage(false);
      return;
    }

    await fetchMore(data.nextCursor!, limit);
    setIsFetchingMore(false);
  }, [data, limit, fetchMore]);

  return {
    loadMore,
    isFetchingMore,
    hasNextPage,
  };
}
